<template>
    <main class="ztb_main policy_main info_details author_list">
      <div class="ztb_box bg_author bg-white">
        
        <div class="author_page">
          <div class="author_inner_box">
          <div class="img_box db auto">
            <img :src="getImgUrl(authorInfo.image)" @error="errPic($event,'avatar')">
          </div>
          <div class="name tc otw">{{authorInfo.author}}</div>
          <div class="author_intro auto">{{authorInfo.authorIntroduce}}</div>
        </div>
      </div>

        <ul class="pl_list article-list">
          <li v-for="item in articles" :key="item.id" class="article-item" @click="showDetail(item.id)">
            <a href="javascript:void(0);" class="db w100p item">
              <div class="img_box">
                <img :src="getImgUrl(item.image)" @error="errPic($event)" class="db">
              </div>
              <div class="brief w100p">
                <div class="title">{{item.title}}</div>
                <div class="intro">{{item.title}}</div>
                <div class="date_price cf">
                  <div class="date fl" v-formatTime>{{item.createdTime}}</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        
        <div class="pagnation">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageIndex"
            :page-sizes="[2,3,4,5]"
            :page-size="pagination.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.totalCount">
          </el-pagination>
        </div>
        
      </div>

      <div class="ztb_latest">
        

        <div class="side_box">
        <div class="title">
         <span>热文作者</span>
        </div>
        <ul class="authors">
          <li v-for="item in topAuthors" :key="item.author" @click="showAuthorDetail(item.author)">
            <a href="javascript:void(0);" class="auto cf">
              <div class="img_box fl">
                <img :src="getImgUrl(item.image)" @error="errPic($event,'avatar')">
              </div>
              <div class="name_intro fr">
                <div class="name">{{item.nickName}}</div>
                <span class="otw">{{item.introduction}}</span>
              </div>
            </a>
          </li>
        </ul>
        </div>


        <div class="sidebar_box">
        <div class="title">
         <span>热文排行</span>
        </div>
        <ul>
          <li v-for="(item,index) in topArticles" @click="getNewsDetail(item.id)" :key="item">
            <a class="cup">
              <i>{{index+1}}</i>
              <img v-show="index===0" :src="getImgUrl(item.image)" @error="errPic($event)" class="w100p">
              <p>{{item.title}}</p>
            </a>
          </li>
        </ul>
        </div>



      </div>
    </main>
</template>
<script setup>
import {useRouter} from "vue-router"
import useAuthorDetail from "@/composable/news/useAuthorDetail"
import useAuthorArticle from "@/composable/news/useAuthorArticle"
import useHotAuthor_Article from "@/composable/news/useHotAuthor_Article"
import {getImgUrl,errPic} from "@/utils/imageRelated"
        const router=useRouter()
        const {authorInfo,getAuthorInfo}=useAuthorDetail()
        const {queryParams,getArticleById,articles,pagination,handleCurrentChange,handleSizeChange}=useAuthorArticle()
        const {topAuthors,topArticles}=useHotAuthor_Article()
        const showDetail=(id)=>{
            router.push({
              name:"NewsDetail",
              query:{id}
            })
        }
        const showAuthorDetail=(id)=>{
          if(id===authorInfo.id) return;
          getAuthorInfo(id)
          queryParams.Author=id;
          getArticleById()
        }
</script>
<style lang="less" scoped>
main,.bg-white{
  background-color: white !important;
}
  .author_intro{
    text-align: center;
  }
  .article-list{
    padding: 0 20px 0 30px;
  }
  .article-item{
    padding:35px 0 30px;
  }
   .pagnation{
    text-align: center;
  }
  .top-article{
    display: flex;
    margin-top: 27px;
    cursor: pointer;
  }
  .top-article-container{
    margin-left: 27px;
    display: flex;
    flex-direction: column;
  }
  .top-article-container>span:nth-child(1){
    font-weight: bold;
  }
  .top-article-container>span:nth-child(2){
    margin-top: 17px;
    flex-grow: 1;
  }
  .top-article>img{
    width: 186px;
    height: 116px;
  }


.cup{cursor:pointer;}
</style>