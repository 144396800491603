import {onMounted, reactive} from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useAuthorDetail(){
    const route=useRoute();
    const authorId=route.query.id

    const authorInfo=reactive({})
    const getAuthorInfo=async(id)=>{
        const [err,data]=await $api.article.getAuthorInfo({AuthorId:id})
        if(!data.succeeded) return;
        Object.assign(authorInfo,data.data)
    }
    onMounted(()=>{
        getAuthorInfo(authorId)
    })
    return {
        authorInfo,getAuthorInfo
    }
}