import {onMounted, reactive, ref} from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useAuthorArticle(){
    const route=useRoute()
    const authorId=route.query.id 
    
    const articles=ref([])
    const queryParams=reactive({
        Author:authorId,
        PageIndex:1,
        PageSize:15
    })
    const getArticleById=async()=>{
        const [err,data]=await $api.article.getArticleByAuthorId(queryParams)
        if(!data.succeeded) return;
        articles.value=data.data.items.sort((a,b)=>new Date(b.createdTime)-new Date(a.createdTime));
        pagination.totalCount=data.data.totalCount
        pagination.pageIndex=data.data.pageIndex
        pagination.pageSize=data.data.pageSize
    }

    const pagination=reactive({
        totalPages:0,
        totalCount:0,
        PageSize:15,
        pageIndex:0
    })
    const handleSizeChange = (size) => {
        queryParams.PageSize=size;
        getArticleById()
    }
    const handleCurrentChange = (current) => {
        queryParams.PageIndex=current;
        getArticleById()
    }
    onMounted(()=>{
        getArticleById()
    })
    return {
        queryParams,getArticleById,
        articles,pagination,
        handleCurrentChange,
        handleSizeChange
    }
}